<template>
  <div>
    <Search class="search-layout" @setSelectStr="setSelectStr">
      <div slot="left" class="search-left">
        <van-icon
          name="arrow-left"
          size="24"
          color="#9B9B9B"
          @click="$router.go(-1)"
        />
      </div>
      <div slot="right" class="search-right">
        <van-button
          type="danger"
          round
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="setSelectGoods"
        >
          搜索
        </van-button>
      </div>
    </Search>
    <!-- 分类筛选 -->
    <van-sticky :offset-top="65" @scroll="changeScroll">
      <van-dropdown-menu :class="{ 'tab-color': showBack }">
        <van-dropdown-item :title="className" ref="dropdown">
          <StockClass @selectClass="selectClass"></StockClass>
        </van-dropdown-item>
        <van-dropdown-item
          :options="option2"
          v-model="shopSort"
          @open="onOpen"
          @change="onChange"
          @close="onClose"
          title-class="font-weight"
        />
      </van-dropdown-menu>
    </van-sticky>
    <div class="content-layout">
      <StockGoods
        ref="goodsList"
        :classId="classId"
        :orderBy="shopSort"
        :goodName="goodName"
      ></StockGoods>
    </div>
  </div>
</template>

<script>
import StockClass from "./components/stock-class";
import Search from "@/components/search/search";
import StockGoods from "@/components/stock-goods/stock-goods.vue";
export default {
  name: "stock-goods",
  components: {
    Search,
    StockGoods,
    StockClass,
  },
  data() {
    return {
      showBack: false,
      menuTitle: "全部分类",
      shopClass: "",
      shopSort: 0,
      option2: [
        { text: "综合排序", value: 0 },
        { text: "销量优先", value: 1 },
        { text: "评分优先", value: 2 },
        { text: "价格从低到高", value: 3 },
        { text: "价格从高到低", value: 4 },
      ],
      active: false,
      goodName: "",
      className: "全部分类",
      classId: 0,
    };
  },
  methods: {
    goToUrl() {
      //页面跳转
      this.$router.push({ name: "remaining-sum", query: { moneyType: 2 } });
    },
    selectClass(item) {
      this.classId = item.id;
      this.className = item.class_name;
      this.$refs.dropdown.toggle(false);
    },
    setSelectStr(value) {
      this.goodName = value;
    },
    setSelectGoods() {
      this.reloadGoods();
    },
    reloadGoods() {
      let _that = this;
      setTimeout(function () {
        _that.$refs.goodsList.reloadGoods();
      }, 50);
    },
    onOpen() {
      this.showBack = true;
    },
    onClose() {
      this.showBack = false;
    },
    onChange(value) {
      this.shopSort = value;
      this.reloadGoods();
    },
    changeScroll(obj) {
      obj.scrollTop >= 1 ? (this.showBack = true) : (this.showBack = false);
    },
  },
};
</script>

<style lang="less" scoped>
.tab-color {
  // 动态背景色
  background-color: #fff;
  transition: all 0.1s;
}
.search-layout {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8000;
  border-bottom: 1px solid #ededed;
}
/deep/.van-sticky--fixed {
  top: 64px !important;
}
// vant组件样式
/deep/.van-dropdown-item__content {
  min-height: 100%;
}
/deep/.van-dropdown-menu__bar {
  background-color: transparent;
  box-shadow: none;
}
/deep/.van-popup {
  background-color: #f9f9f9;
}
/deep/.font-weight {
  font-weight: 500;
}
/deep/.van-dropdown-menu__item {
  justify-content: start;
  padding: 0 10px;
  flex: none;
}
/deep/.van-sidebar-item__text {
  font-size: 17px;
}
// 商品列表布局
.content-layout {
  padding: 54px 0;
}
</style>
