<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <van-grid :column-num="2" gutter="12" :border="false">
      <van-grid-item
        @click="goToUrl(item.id)"
        v-for="(item, index) in goods_list"
        :key="index"
      >
        <template default>
          <div class="goods-layout">
            <div class="img-box">
              <img :src="item.cover_img" />
            </div>
            <div class="goods-detail">
              <div class="goods-title">{{ item.good_name }}</div>
              <div>
                <span class="price">
                  <span>￥</span>
                  <span class="integer">{{ toInteger(item.price) }}</span>
                  <span>{{ twoFloating(item.price) }}</span>
                </span>
                <span class="payment">{{ item.sales }}人付款</span>
              </div>
            </div>
          </div>
        </template>
      </van-grid-item>
    </van-grid>
  </van-list>
</template>

<script>
import { getPurchasesList } from "@/api/purchases";
import tools from "@/common/js/tools";

export default {
  name: "live-goods-list",
  props: ["orderBy", "goodName", "classId"],
  data() {
    return {
      loading: false,
      finished: false,
      goods_list: [],
      page: 1,
      total: 100,
      isNext: false,
      isAjax: false,
      lon: "",
      lat: "",
      orderBys: "",
    };
  },
  watch: {
    orderBy(n, o) {
      this.orderBys = n;
    },
    classId(n, o) {
      if (n !== o) {
        this.reloadGoods();
      }
    },
  },
  methods: {
    goToUrl(goodsId) {
      this.$router.push({
        name: "stock-details",
        query: { goodsId: goodsId, type: 2 },
      });
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    onLoad() {
      this.getGoodsList();
    },
    reloadGoods() {
      this.finished = false;
      this.total = 100;
      this.page = 1;
      this.goods_list = [];
      this.getGoodsList();
    },
    async getGoodsList() {
      if (this.total <= this.page || this.finished || this.isAjax) {
        return false;
      }
      this.isAjax = true;
      // 请求分流头
      const sendData = {
        good_name: this.goodName,
        order_by: this.orderBys,
        class_id: this.classId,
        page: this.page,
      };
      const res = await getPurchasesList(sendData);
      this.isAjax = false;
      if (res.code * 1 === 1) {
        ++this.page;
        this.goods_list = this.goods_list.concat(res.data.list);
        this.total = res.data.total;
        this.loading = false;
        if (this.goods_list.length >= this.total) {
          this.finished = true;
        }
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price =num*1;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      //
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
// 公共样式
img {
  width: 100%;
  display: block;
}
// vant组件样式修改
/deep/.van-grid-item__content {
  padding: 0;
  border-radius: 16px;
}
/deep/.van-grid-item {
  width: 188px;
}
.goods-layout {
  border-radius: 10px;
  overflow: hidden;
  .img-box {
    width: 100%;
    img {
      display: block;
      width: 188px;
      height: 188px;
    }
  }
}
.van-list {
  margin-top: 12px;
}
// 商品详情
.goods-detail {
  padding: 10px;
  .goods-title {
    width: 170px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 12px;
  }
  .price {
    color: #ed301d;
    font-size: 14px;
    .integer {
      font-size: 18px;
    }
  }
  .payment {
    color: #bbbbbb;
    padding-left: 16px;
    font-size: 13px;
  }
}
</style>
